import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

import Head from "../components/head"
import Layout from "../components/layout"
import * as blogStyles from "./blog.module.scss"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fields: { draft: { eq: false } } }) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
            }
            html
            excerpt
            id
            fields {
              slug
              draft
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <Head title="Blog" />
      <h1>Blog</h1>

      <p>
        {posts.length} {posts.length === 1 ? "post" : "posts"}
      </p>

      <ol className={blogStyles.posts}>
        {posts.map(({ node }) => (
          <li key={node.id} className={blogStyles.post}>
            <h2>
              <Link to={`/blog/${node.fields.slug}`}>
                {node.frontmatter.title}
              </Link>
            </h2>
            <p>{node.frontmatter.date}</p>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default BlogPage
